import { Box, Container, Typography } from "@mui/material";
import { Grid, SearchBar } from "@common";
import { useSearchBar } from "@services/Search";
import searchBg from "@assets/images/search-bg.png";
import { styled } from "@mui/material/styles";
import { useAppNavigate } from "@services/Navigation";

export const ImageWrapper = styled(Box)({
  width: "100%",
  height: "600px",
  backgroundImage: `url(${searchBg})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  "&:before": {
    content: '""',
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    background: "rgba(0,0,0,0.5)",
  },
});
function SearchSection() {
  const navigate = useAppNavigate();
  const searchBar = useSearchBar({
    onSubmit: ({ filter }) => navigate.toResults(filter),
  });

  return (
    <ImageWrapper>
      <Container sx={{ position: "relative" }}>
        <Grid xs={12}>
          <Typography
            variant="p1"
            color="green.primary"
            sx={{ textTransform: "uppercase" }}
            gutterBottom
          >
            Search tee times
          </Typography>
          <Typography variant="h1" gutterBottom color="white" mt={3}>
            Find your tee time, <br />
            every time
          </Typography>
          <SearchBar {...searchBar} />
        </Grid>
      </Container>
    </ImageWrapper>
  );
}

export default SearchSection;
