import { Grid, RangeSlider } from "@common";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
} from "@mui/material";
import { useState } from "react";

import { FilterPaper, FormItemContainer } from "./styles";
import {
  DISTANCE_MAX,
  DISTANCE_MIN,
  PRICE_MAX,
  PRICE_MIN,
  useFilter,
} from "@services/Search";

export const MobileResultsFilter = () => {
  const [filterPaper, openFilterPaper] = useState<
    "holes" | "distance" | "price" | ""
  >("");
  const [filter, setFilter] = useFilter();
  const [holes, setHoles] = useState({
    eighteenHoles: filter?.eighteenHoles,
    nineHoles: filter?.nineHoles,
  });
  const [price, setPrice] = useState(filter?.price);
  const [distance, setDistance] = useState(filter?.distance);
  const getHoles = () => {
    if (filter?.eighteenHoles && filter?.nineHoles) {
      return "18,9";
    } else if (filter?.eighteenHoles) {
      return "18";
    } else if (filter?.nineHoles) {
      return "9";
    } else {
      return "";
    }
  };

  const getPrice = () => {
    if (price[0] === PRICE_MIN && price[1] === PRICE_MAX) {
      return "All";
    } else {
      return `$${price[0]} - $${price[1]}`;
    }
  };

  const getDistance = () => {
    if (distance[0] === DISTANCE_MIN && distance[1] === DISTANCE_MAX) {
      return "All";
    } else {
      return `${distance[0]} - ${distance[1]} miles`;
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid xs={4}>
        <Button
          fullWidth
          variant="outlined"
          onClick={() => openFilterPaper("holes")}
        >
          Holes: {getHoles()}
        </Button>
        {filterPaper === "holes" && (
          <FilterPaper>
            <FormItemContainer>
              <FormControlLabel
                label="18 holes"
                control={
                  <Checkbox
                    name="eighteenHoles"
                    checked={holes?.eighteenHoles}
                    onChange={(e) =>
                      setHoles((holes) => ({
                        ...holes,
                        eighteenHoles: e.target.checked,
                      }))
                    }
                  />
                }
              />
              <FormControlLabel
                label="9 holes"
                control={
                  <Checkbox
                    name="nineHoles"
                    checked={holes?.nineHoles}
                    onChange={(e) =>
                      setHoles((holes) => ({
                        ...holes,
                        nineHoles: e.target.checked,
                      }))
                    }
                  />
                }
              />
              <Divider />
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => openFilterPaper("")}
                >
                  Cancel
                </Button>
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => {
                    setFilter({
                      ...filter,
                      eighteenHoles: holes.eighteenHoles,
                      nineHoles: holes.nineHoles,
                    });
                    openFilterPaper("");
                  }}
                >
                  Apply
                </Button>
              </Box>
            </FormItemContainer>
          </FilterPaper>
        )}
      </Grid>
      <Grid xs={4}>
        <Button
          fullWidth
          variant="outlined"
          onClick={() => openFilterPaper("price")}
        >
          Price: {getPrice()}
        </Button>
        {filterPaper === "price" && (
          <FilterPaper>
            <FormItemContainer>
              <RangeSlider
                max={100}
                min={0}
                name="price"
                handleChangeCommitted={(newValue) =>
                  setPrice(newValue as number[])
                }
                units="$"
                defaultValue={price}
              />
            </FormItemContainer>
            <Divider />
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="text"
                color="primary"
                onClick={() => openFilterPaper("")}
              >
                Cancel
              </Button>
              <Button
                variant="text"
                color="primary"
                onClick={() => {
                  setFilter({ ...filter, price });
                  openFilterPaper("");
                }}
              >
                Apply
              </Button>
            </Box>
          </FilterPaper>
        )}
      </Grid>
      <Grid xs={4}>
        <Button
          fullWidth
          variant="outlined"
          onClick={() => openFilterPaper("distance")}
        >
          Distance: {getDistance()}
        </Button>
        {filterPaper === "distance" && (
          <FilterPaper>
            <FormItemContainer>
              <RangeSlider
                max={50}
                min={0}
                name="distance"
                handleChangeCommitted={(newValue) =>
                  setDistance(newValue as number[])
                }
                units="miles"
                defaultValue={distance}
              />
            </FormItemContainer>
            <Divider />
            <Box display="flex" justifyContent="flex-end">
              <Button variant="text" onClick={() => openFilterPaper("")}>
                Cancel
              </Button>
              <Button
                variant="text"
                color="primary"
                onClick={() => {
                  setFilter({ ...filter, distance });
                  openFilterPaper("");
                }}
              >
                Apply
              </Button>
            </Box>
          </FilterPaper>
        )}
      </Grid>
    </Grid>
  );
};
