import { Box, CircularProgress, Modal, Typography } from "@mui/material";

function CheckoutConfirmationModal({ open }: { open: boolean }) {
  return (
    <Modal open={open}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress sx={{ color: "green.primary" }} />
        </Box>
        <Typography variant="h4" mt={2} textAlign={"center"}>
          Connecting to our payment partner, Stripe...
        </Typography>
      </Box>
    </Modal>
  );
}

export default CheckoutConfirmationModal;
