import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { RecoilRoot } from "recoil";
import RecoilNexus from "recoil-nexus";
import { QueryClientProvider } from "@tanstack/react-query";

import { SnackbarProvider } from "notistack";
import { ReactNode } from "react";
import { theme } from "@/theme/theme";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { ErrorBoundary } from "@sentry/react";
import { ErrorPage } from "@pages/ErrorPage/ErrorPage";
import { queryClient } from "@services/QueryClient";

export function AppProviders({ children }: { children: ReactNode }) {
  return (
    <RecoilRoot>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <RecoilNexus />
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <StyledEngineProvider injectFirst>
              <SnackbarProvider
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
              >
                <ErrorBoundary fallback={ErrorPage}>{children}</ErrorBoundary>
              </SnackbarProvider>
            </StyledEngineProvider>
          </ThemeProvider>
          <ReactQueryDevtools initialIsOpen={false} client={queryClient} />
        </QueryClientProvider>
      </LocalizationProvider>
    </RecoilRoot>
  );
}
