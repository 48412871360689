import { Container, Box, Typography } from "@mui/material";
import { Link } from "@/components/common/Link";
import { styled } from "@mui/material/styles";
import { appConfig } from "@services/AppConfig";

const Wrapper = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(1.6),
  paddingBottom: theme.spacing(1.6),
}));

const Content = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const RightText = styled(Typography)({
  color: "#908E8E",
  margin: "auto",
});

const LinkText = styled(Link)({
  fontWeight: "bold",
  color: "#3A3335",
  marginRight: "14px",
  marginTop: "10px",
  cursor: "pointer",
  "&:hover": {
    textDecoration: "none",
  },
});

export const Footer = () => {
  return (
    <Wrapper>
      <Content>
        <RightText>
          © 2023 TeeTimePortal.com, Inc. All rights reserved
        </RightText>
        <LinkText
          href={`mailto:${appConfig.supportEmail}?subject=Feedback&body=`}
        >
          Give us feedback
        </LinkText>
      </Content>
    </Wrapper>
  );
};
