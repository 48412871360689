import { appConfig } from "@services/AppConfig";
import {
  setBaseUrl,
  axios,
} from "@thesparklaboratory/teetimeportal-react-query-client/dist/client";
import { getAuthenticatedUser } from "@services/Authentication";

setBaseUrl(appConfig.baseUrl);

axios.interceptors.request.use(async function (config) {
  const authenticatedUser = getAuthenticatedUser();
  const idToken = authenticatedUser?.idToken;
  if (idToken) {
    config.headers.authorization = `Bearer ${idToken}`;
  }

  return config;
});

export default axios;
