import { useState } from "react";
import { Box, IconButton, Popover, Typography, useTheme } from "@mui/material";

import { ShareAltIcon } from "@common";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";

export const ShareButton = () => {
  const [shareMessage] = useState("");
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const theme = useTheme();
  const shareUrl = document.location.href;
  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box p={1}>
          <TwitterShareButton
            url={shareUrl}
            title="TeeTimePortal-Golf Course Times"
          >
            <Box mr={0.5}>
              <TwitterIcon size={32} round={true} />
            </Box>
          </TwitterShareButton>
          <FacebookShareButton
            url={shareUrl}
            title="TeeTimePortal-Golf Course Times"
          >
            <Box mr={0.5}>
              <FacebookIcon size={32} round={true} />
            </Box>
          </FacebookShareButton>
          <EmailShareButton
            url={shareUrl}
            subject="TeeTimePortal-Golf Course Times"
          >
            <EmailIcon size={32} round={true} />
          </EmailShareButton>
        </Box>
      </Popover>
      <IconButton
        onClick={(event) => setAnchorEl(event.currentTarget)}
        sx={{
          borderRadius: theme.shape.borderRadius,
          color: theme.palette.text.primary,
        }}
      >
        <ShareAltIcon />
        <Typography>{shareMessage ? shareMessage : "Share"}</Typography>
      </IconButton>
    </>
  );
};
