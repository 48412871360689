import { LoadingWrapper } from "@common";
import { useEffect, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useLoginMutation } from "@services/Authentication";
import { useAppNavigate } from "@services/Navigation";
import { logger } from "@services/Logger";
import { LoginForm, loginSchema } from "@pages/LoginPage/LoginForm";
import { AccountPageContainer } from "@common/AccoutPageContainer";
import { FirebaseError } from "@firebase/util";

const SOMETHING_UNEXPECTED_ERROR_MESSAGE =
  "Something unexpected occurred try again later";

type FormValues = {
  email: string;
  password: string;
};
const LoginPage = () => {
  const navigate = useAppNavigate();

  const { control, handleSubmit } = useForm<FormValues>({
    resolver: yupResolver(loginSchema),
  });

  const {
    error,
    mutate: submitLogin,
    isPending,
  } = useLoginMutation({
    onSuccess: () => navigate.toHome(),
  });

  useEffect(() => {
    // noinspection SuspiciousTypeOfGuard
    if (
      error &&
      error instanceof FirebaseError &&
      ![
        "auth/user-not-found",
        "auth/wrong-password",
        "auth/too-many-requests",
      ].includes(error.code)
    ) {
      logger.error({
        message: "Login failed with unknown firebase error",
        error,
        data: { errorCode: error.code },
      });
    } else {
      logger.error({
        message: "Login failed with unknown error",
        error,
      });
    }
  }, [error]);

  const errorMessage = useMemo(() => {
    if (!error) return "";

    // noinspection SuspiciousTypeOfGuard
    if (error instanceof FirebaseError) {
      if (
        error.code === "auth/user-not-found" ||
        error.code === "auth/wrong-password"
      ) {
        return "Your email or password is incorrect";
      } else if (error.code === "auth/too-many-requests") {
        return "Too many attempts, please try again later";
      } else {
        return SOMETHING_UNEXPECTED_ERROR_MESSAGE;
      }
    } else {
      return SOMETHING_UNEXPECTED_ERROR_MESSAGE;
    }
  }, [error]);

  const handleSignIn = async (data: FormValues) => {
    const { email, password } = data;
    submitLogin({ email, password });
  };

  return (
    <LoadingWrapper visible={isPending}>
      <AccountPageContainer>
        <LoginForm
          onSubmit={handleSubmit(handleSignIn)}
          control={control}
          errorMessage={errorMessage}
        />
      </AccountPageContainer>
    </LoadingWrapper>
  );
};

export default LoginPage;
