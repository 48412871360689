import { FirebaseApp, initializeApp } from "firebase/app";
import {
  Analytics,
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from "firebase/analytics";
import { CustomParams } from "@firebase/analytics-types";
import {
  getAuth,
  signInWithEmailAndPassword,
  confirmPasswordReset,
  browserLocalPersistence,
  sendPasswordResetEmail,
} from "@firebase/auth";
import { logger } from "@services/Logger";
import { appConfig } from "@services/AppConfig";

let firebaseApp: FirebaseApp | undefined;
let analytics: Analytics | undefined;

export const useFirebase = () => {
  if (!firebaseApp) {
    firebaseApp = initializeApp({
      apiKey: "AIzaSyBB0JQqsHfZcFr01eEb-RkF_5Hhcr-Qwf8",
      authDomain: "teetimeportal.firebaseapp.com",
      projectId: "teetimeportal",
      storageBucket: "teetimeportal.appspot.com",
      messagingSenderId: "140893670688",
      appId: "1:140893670688:web:4b0e5bfd1033886836db45",
      measurementId: "G-4ZPJSRZ6YM",
    });

    getAuth(firebaseApp)
      .setPersistence(browserLocalPersistence)
      .catch((error) => {
        logger.error({ message: "Error setting persistence", error });
      });
  }
  return firebaseApp;
};

export const useAnalytics = () => {
  const firebase = useFirebase();
  if (!analytics) {
    analytics = getAnalytics(firebase);
  }

  return analytics;
};

export function getFirebaseAuth() {
  const auth = getAuth(firebaseApp);
  auth.tenantId = appConfig.googleTenantId;

  return auth;
}

export async function getCurrentFirebaseUser() {
  const auth = getFirebaseAuth();

  await auth.authStateReady();

  return auth.currentUser;
}

export async function signIn({
  email,
  password,
}: {
  email: string;
  password: string;
}) {
  const auth = getFirebaseAuth();
  return signInWithEmailAndPassword(auth, email, password);
}

export async function signOutOfFirebase() {
  const auth = getFirebaseAuth();
  await auth.signOut();
}

export const useLogEvent = () => {
  const analytics = useAnalytics();
  return (
    eventName: string,
    eventParams?: { [p: string]: unknown } | undefined,
  ) => {
    logEvent(analytics, eventName, eventParams);
  };
};

export async function resetPasswordFirebase(email: string) {
  const auth = getFirebaseAuth();
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (error) {
    logger.error({ message: "Error sending password reset email", error });
    throw error;
  }
}

export async function confirmPasswordResetFirebase({
  oobCode,
  password,
}: {
  oobCode: string;
  password: string;
}) {
  const auth = getFirebaseAuth();
  try {
    await confirmPasswordReset(auth, oobCode, password);
  } catch (error) {
    logger.error({ message: "Error confirming password reset", error });
    throw error;
  }
}

export const useSetUserId = () => {
  const analytics = useAnalytics();
  return (userId: string) => {
    setUserId(analytics, userId);
  };
};

export const useSetUserProperties = () => {
  const analytics = useAnalytics();
  return (userProperties: CustomParams) => {
    setUserProperties(analytics, userProperties);
  };
};
