import { Link, PhoneIcon, GolfIcon, WebIcon, Pin } from "@common";
import { Course } from "@/types/course";
import { useTheme, Box, Typography } from "@mui/material";

type Props = {
  img: string;
  courseData: Course;
};

export const CourseDetails = ({ img, courseData }: Props) => {
  const {
    facility: {
      address: { url: web, phone, street, city, region },
    },
  } = courseData;

  const location = `${street}, ${city}, ${region}`;
  const url = web?.indexOf("http") !== 0 ? "https://" + web : web;
  const theme = useTheme();
  const par = "";
  const yards = "";
  const holes = 18;
  return (
    <Box>
      <Box
        textAlign="center"
        sx={{
          width: "100%",
          borderRadius: theme.shape.borderRadius,
          margin: theme.spacing(2, 0),
          "& img": {
            height: 200,
            width: "100%",
            objectFit: "cover",
          },
        }}
      >
        <img src={img} />
      </Box>
      <Box display="flex" justifyContent="space-between" flexWrap="wrap">
        {web && (
          <Box
            sx={{
              width: "100%",
              [theme.breakpoints.up("md")]: {
                width: "48%",
              },
            }}
            mt={2}
            mb={2}
            display="flex"
          >
            <WebIcon
              sx={{ marginRight: theme.spacing(1.5), alignSelf: "center" }}
            />
            <Box>
              <Typography variant="subtitle1">Website</Typography>
              <Link sx={{ color: theme.palette.primary.main }} href={url}>
                {web}
              </Link>
            </Box>
          </Box>
        )}
        {phone && (
          <Box
            sx={{
              width: "100%",
              [theme.breakpoints.up("md")]: {
                width: "48%",
              },
            }}
            mt={2}
            mb={2}
            display="flex"
          >
            <PhoneIcon
              sx={{ marginRight: theme.spacing(1.5), alignSelf: "center" }}
            />
            <Box>
              <Typography variant="subtitle1">Phone Number</Typography>
              <Link
                sx={{ color: theme.palette.primary.main }}
                href={`tel:${phone}`}
              >
                {phone}
              </Link>
            </Box>
          </Box>
        )}
        <Box
          sx={{
            width: "100%",
            [theme.breakpoints.up("md")]: {
              width: "48%",
            },
          }}
          width="100%"
          mt={2}
          mb={2}
          display="flex"
        >
          <GolfIcon
            sx={{ marginRight: theme.spacing(1.5), alignSelf: "center" }}
          />
          <Box width="100%">
            <Typography variant="subtitle1">Course Information</Typography>
            <Box display="flex">
              {holes && <Typography mr={1.5}>{holes} holes</Typography>}
              {par && (
                <Typography mr={1.5}>Par {par ? `$${par}` : "--"}</Typography>
              )}
              {yards && (
                <Typography>
                  {new Intl.NumberFormat().format(yards) || "--"} yards
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
        {location && (
          <Box
            sx={{
              width: "100%",
              [theme.breakpoints.up("md")]: {
                width: "48%",
              },
            }}
            mt={2}
            mb={2}
            display="flex"
          >
            <Pin
              sx={{ marginRight: theme.spacing(1.5), alignSelf: "center" }}
            />
            <Box>
              <Typography variant="subtitle1">Location</Typography>
              <Typography>{location}</Typography>
              <Link
                sx={{
                  margin: theme.spacing(0.8, 0),
                  display: "block",
                  fontWeight: "bold",
                }}
                color="primary"
                href={`https://maps.google.com/?daddr=${encodeURI(location)}`}
              >
                Get Directions
              </Link>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
