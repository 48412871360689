import api from "@api";
import { formatISO, set } from "date-fns";
import { Course, CourseResults } from "@/types/course";
import { useQuery } from "@tanstack/react-query";
import { Filter } from "@services/Search";
import { useEffect } from "react";
import { logger } from "@services/Logger";
import { useSnackbar } from "notistack";
import { TIME_SLIDER_LATEST_TIME_SELECTABLE } from "@common";

type FilterQueryParams = {
  courseId?: string;
  priceMin?: number;
  distanceMax?: number;
  priceMax?: number;
  distanceMin?: number;
  lng?: number;
  dateStart?: string;
  players?: number;
  dateEnd?: string;
  lat?: number;
  holes: number[];
};

function getParams(filters: Filter): FilterQueryParams {
  const startingHour = filters?.time?.value[0];
  const endingHour =
    filters?.time?.value[1] === TIME_SLIDER_LATEST_TIME_SELECTABLE
      ? 24
      : filters?.time?.value[1];

  let dateStart = set(new Date(filters?.date), {
    hours: startingHour,
    minutes: 0,
    seconds: 0,
  });
  if (dateStart <= new Date()) dateStart = new Date();

  const dateEnd = set(new Date(filters?.date), {
    hours: endingHour,
    minutes: 0,
    seconds: 0,
  });

  const { lat, lng } = filters?.location?.geocode || {
    lat: undefined,
    lng: undefined,
  };
  const players = filters?.count;
  const [priceMin, priceMaxRaw] = filters?.price || [];
  const priceMax = priceMaxRaw === 100 ? undefined : priceMaxRaw;
  const [distanceMin, distanceMax] = filters?.distance || [];
  const holes = [];

  if (filters?.nineHoles) holes.push(9);
  if (filters?.eighteenHoles) holes.push(18);

  return {
    courseId: filters?.courseId,
    lat,
    lng,
    dateStart: formatISO(dateStart),
    dateEnd: formatISO(dateEnd),
    players,
    priceMin,
    priceMax,
    distanceMin,
    distanceMax,
    holes,
  };
}

export const getCourses = async (filters: Filter): Promise<CourseResults[]> => {
  try {
    const params = getParams(filters);
    const { data } = await api.get("/search", { params });
    return data;
  } catch (error) {
    throw new Error("Error fetching courses");
  }
};

export function useCourse({ filter }: { filter: Filter }) {
  const { data: course, isLoading } = useQuery({
    queryKey: ["course", filter],
    queryFn: async () => await getCourse({ filter }),
  });
  return { course, isLoading };
}

export const getCourse = async ({
  filter,
}: {
  filter: Filter;
}): Promise<Course> => {
  const params = getParams(filter);
  if (!filter.courseId) {
    throw new Error("Course ID is required");
  }
  try {
    const { data } = await api.get(`/course`, { params });
    return data;
  } catch (error) {
    throw new Error("Error fetching course details");
  }
};

export function useCourses({ filter }: { filter: Filter }) {
  const {
    data: courses = [],
    isLoading,
    error,
  } = useQuery({
    queryKey: ["courses", filter],
    queryFn: async () => await getCourses(filter),
  });

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (error) {
      logger.error({ message: "Error fetching courses", error });
      enqueueSnackbar(
        "Sorry an error occurred searching courses has been reported. Please try again later.",
        {
          variant: "error",
        },
      );
    }
  }, [error]);

  return { courses, isLoading };
}
