import { Box, Container, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { SectionContainer } from "@pages/LandingPage/styles";
import { ClockIconFilled, GolfIcon } from "@common";
import { CalendarIcon } from "@mui/x-date-pickers";

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[500],
  textAlign: "left",
  padding: theme.spacing(2.5),
  borderRadius: "4px",
  [theme.breakpoints.up("md")]: {
    minHeight: "300px",
  },
}));

function AboutSection() {
  const featureList = [
    {
      title: "Lots of choices",
      description:
        "From championship greens to hidden gems, TeeTime Portal offers hundreds of courses to choose from.",
      icon: (
        <Box color="green.primary">
          <GolfIcon sx={{ fontSize: 40 }} />
        </Box>
      ),
    },
    {
      title: "Time-Saving Convenience",
      description:
        "Spend less time searching across multiple sites and more time out on the golf course.",
      icon: (
        <Box color="green.primary">
          <ClockIconFilled sx={{ fontSize: 40 }} />
        </Box>
      ),
    },
    {
      title: "Real-Time Availability",
      description:
        "View real-time availability, so you can make informed decisions on the spot.",
      icon: (
        <Box color="green.primary">
          <CalendarIcon sx={{ fontSize: 40 }} />
        </Box>
      ),
    },
  ];

  return (
    <SectionContainer>
      <Container>
        <Grid container>
          <Grid container spacing={2}>
            <Grid xs={12} md={4} item>
              <Typography
                variant="p1"
                gutterBottom
                color="green.secondary"
                sx={{
                  textTransform: "uppercase",
                }}
              >
                About Us
              </Typography>
            </Grid>
            <Grid xs={12} md={8} item>
              <Typography variant="h2" gutterBottom>
                Are you tired of browsing multiple websites to book your
                preferred tee time?
              </Typography>
              <Typography variant="p2" gutterBottom color="grey.600">
                Look no further! TeeTime Portal brings you a seamless and
                efficient solution by aggregating tee times from various sources
                into one convenient location. Whether you're a seasoned golfer
                or just starting, our platform is designed to simplify your
                golfing experience.
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={4} mt={2}>
            {featureList.map((feature, index) => (
              <Grid sm={4} key={index} item>
                <Item>
                  {feature.icon}
                  <Typography variant="h4" sx={{ mt: 2 }}>
                    {feature.title}
                  </Typography>
                  <Box mt={1.5}>
                    <Typography variant="p2" color={"text.grey"}>
                      {feature.description}
                    </Typography>
                  </Box>
                </Item>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Container>
    </SectionContainer>
  );
}

export default AboutSection;
