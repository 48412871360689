import { useMutation } from "@tanstack/react-query";
import { atom, useRecoilValue } from "recoil";
import { getRecoil, resetRecoil, setRecoil } from "recoil-nexus";
import { logger } from "@services/Logger";
import {
  confirmPasswordResetFirebase,
  getCurrentFirebaseUser,
  getFirebaseAuth,
  resetPasswordFirebase,
  signIn,
  signOutOfFirebase,
} from "@services/Firebase";
import { queryClient } from "@services/QueryClient";
import { getGetUserQueryKey } from "@thesparklaboratory/teetimeportal-react-query-client";

type AuthenticatedUser = {
  idToken: string;
};

export const authenticatedUserState = atom<AuthenticatedUser | undefined>({
  key: "authenticatedUserState",
  default: undefined,
});

export function useAuthenticatedUser() {
  return useRecoilValue(authenticatedUserState);
}

export function getAuthenticatedUser() {
  return getRecoil(authenticatedUserState);
}

export function setAuthenticatedUser({ user }: { user: AuthenticatedUser }) {
  setRecoil(authenticatedUserState, user);
}

export function resetAuthenticatedUser() {
  resetRecoil(authenticatedUserState);
}

export function useAuthenticationMutation() {
  return useMutation({
    mutationFn: authenticate,
    mutationKey: ["authenticate"],
  });
}

export async function submitLogin({
  email,
  password,
}: {
  email: string;
  password: string;
}) {
  await signIn({ email, password });
  await authenticate();
}

export function useLoginMutation({ onSuccess }: { onSuccess: () => void }) {
  return useMutation({
    mutationFn: submitLogin,
    mutationKey: ["login"],
    onSuccess,
  });
}

/**
 * Check for an existing user and set the authenticated user if one exists
 */
export async function authenticate() {
  await getFirebaseAuth().authStateReady();
  try {
    const firebaseUser = await getCurrentFirebaseUser();
    if (firebaseUser) {
      const idToken = await firebaseUser.getIdToken();
      setAuthenticatedUser({
        user: {
          idToken,
        },
      });
      await queryClient.invalidateQueries({ queryKey: getGetUserQueryKey() });
    }
  } catch (error) {
    logger.error({
      message: "An unexpected error occurred while fetching user attributes",
      error: error,
    });
  }
}

export function useResetPasswordMutation({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: (error: unknown) => void;
}) {
  return useMutation({
    mutationFn: resetPassword,
    mutationKey: ["resetPassword"],
    onSuccess,
    onError,
  });
}

export async function resetPassword({ email }: { email: string }) {
  await resetPasswordFirebase(email);
}

export function useConfirmResetPasswordMutation({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: (e: unknown) => void;
}) {
  return useMutation({
    mutationFn: confirmResetPassword,
    mutationKey: ["confirmResetPassword"],
    onSuccess,
    onError,
  });
}

export async function confirmResetPassword({
  oobCode,
  password,
}: {
  oobCode: string;
  password: string;
}) {
  await confirmPasswordResetFirebase({
    oobCode: oobCode,
    password,
  });
}

export async function signOut() {
  await signOutOfFirebase();
  resetAuthenticatedUser();
  queryClient.removeQueries();
}
