import { ErrorBlock, Grid, TeeTimeCard } from "@common";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { DesktopResultsFilter } from "./DesktopResultsFilter";
import { MobileResultsFilter } from "./MobileResultsFilter";
import { ErrorBlockContainer } from "./styles";

import { CourseResults } from "@/types/course";

export const Results = ({ courses }: { courses: CourseResults[] }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = !useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      {isMobile && <MobileResultsFilter />}
      <Grid container spacing={5}>
        <Grid xs={12} md={10}>
          {courses.length > 0 ? (
            <Stack spacing={2}>
              {courses.map((course: CourseResults, index: number) => (
                <TeeTimeCard key={index} course={course} />
              ))}
            </Stack>
          ) : (
            <Grid container justifyContent="center">
              <ErrorBlockContainer>
                <ErrorBlock
                  title="No available tee times in your criteria"
                  description="Please edit your search and try again."
                />
              </ErrorBlockContainer>
            </Grid>
          )}
        </Grid>
        {isDesktop && (
          <Grid xs={12} md={2}>
            <DesktopResultsFilter />
          </Grid>
        )}
      </Grid>
    </>
  );
};
