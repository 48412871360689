import { useAuthenticationMutation } from "@services/Authentication";
import { Suspense, useEffect } from "react";
import { useAppErrorHandlers } from "@services/ErrorMessaging";
import AppRouter from "./routes/AppRouter";
import { FullScreenCircularProgress, LoadingScreen } from "@common";
import { TeeTimeAssistantDrawer } from "@/components/assistant/TeeTimeAssistantDrawer";
import { appConfig } from "@services/AppConfig";
import { useGoogleMapsApi } from "@services/Google";
import { useSyncFilterWithQueryParams } from "@services/Search";
import { useFirebase } from "@services/Firebase";

const { isAssistantEnabled } = appConfig;

export default function App() {
  useFirebase();

  const {
    isPending,
    mutate: authenticate,
    error: authenticateError,
  } = useAuthenticationMutation();

  useAppErrorHandlers([
    {
      error: authenticateError,
      id: "App-authenticateError",
    },
  ]);

  useEffect(() => authenticate(), []);

  useSyncFilterWithQueryParams();

  const isGoogleMapsLoaded = useGoogleMapsApi();

  if (isPending || !isGoogleMapsLoaded) return <FullScreenCircularProgress />;

  return (
    <Suspense fallback={<LoadingScreen isLoading />}>
      <AppRouter />
      {isAssistantEnabled && <TeeTimeAssistantDrawer />}
    </Suspense>
  );
}
