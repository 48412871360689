import { useEffect, useState } from "react";

import { useCourse } from "@/services/Courses";
import {
  Button,
  LoadingScreen,
  Pin,
  ShareButton,
  CoursePageTabNavigation,
} from "@common";
import { Box, Container, Typography, useTheme } from "@mui/material";
import { getDistanceFromLatLonInMiles } from "@utils";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import { useAppNavigate } from "@services/Navigation";
import { useFilter } from "@services/Search";

const CoursePage = () => {
  const { courseId = "" } = useParams();
  const [filter] = useFilter();
  const { course, isLoading } = useCourse({
    filter: { ...filter, courseId },
  });

  const [distance, setDistance] = useState("");

  const theme = useTheme();
  const navigate = useAppNavigate();

  useEffect(() => {
    if (course && course?.facility && filter && filter.location) {
      const {
        facility: {
          address: { latitude: courseLat, longitude: courseLng },
        },
      } = course;

      const { lat: locationLat, lng: locationLng } =
        filter?.location?.geocode || {};
      if (courseLat && courseLng && locationLat && locationLng) {
        const distance = getDistanceFromLatLonInMiles(
          courseLat,
          courseLng,
          locationLat,
          locationLng,
        ).toFixed(2);

        setDistance(distance);
      }
    }
  }, [course, filter]);

  const courseNameWithFacility =
    course?.name === course?.facility?.name
      ? course?.name
      : `${course?.facility?.name} - ${course?.name}`;

  return (
    <LoadingScreen isLoading={isLoading}>
      <Box sx={{ flex: 1, overflow: "hidden", position: "relative" }}>
        <Container maxWidth="md">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="caption">
              {filter?.location?.description &&
                `${filter.location?.description}, `}
              {filter?.date && `${format(filter?.date, "EEEE, LLL d")}, `}
              {filter?.time && `${filter?.time?.alignment}, `}
              {filter?.count && `${filter?.count} Golfers`}
            </Typography>
            <Button
              onClick={() => navigate.toResults()}
              variant="text"
              color="primary"
              sx={{ color: theme.palette.primary.main }}
            >
              Change
            </Button>
          </Box>
          <Typography sx={{ fontSize: theme.spacing(2.2), fontWeight: "bold" }}>
            {course && courseNameWithFacility}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box display="flex">
              {distance && (
                <>
                  <Pin fontSize="small" />
                  <Typography>{distance} miles</Typography>
                </>
              )}
            </Box>
            <ShareButton />
          </Box>
          {course && <CoursePageTabNavigation course={course} />}
        </Container>
      </Box>
    </LoadingScreen>
  );
};

export default CoursePage;
