import { CourseResults } from "@/types/course";
import { ClockIcon, StarIcon } from "@common";
import { Box, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { getRatingWord, getTeeTimeSummary } from "@utils";
import { isBefore, setHours, setMinutes, setSeconds } from "date-fns";

import { useFilter } from "@services/Search";
import { useAppNavigate } from "@services/Navigation";

const Container = styled(Box)(({ theme }) => ({
  borderBottom: "0.8px solid #E2E3E4",
  paddingBottom: theme.spacing(1.4),
  [theme.breakpoints.up("md")]: {
    cursor: "pointer",
    border: "1px solid #EDE8E9",
    borderRadius: theme.spacing(0.6),
    marginTop: theme.spacing(2),
  },
}));

export const TeeTimeCard = ({ course }: { course: CourseResults }) => {
  const theme = useTheme();
  const navigate = useAppNavigate();
  const [filter] = useFilter();
  let dateStart = setSeconds(
    setMinutes(setHours(filter?.date, filter?.time?.value[0]), 0),
    0,
  );
  // Check if dateStart is in the past
  if (isBefore(dateStart, new Date())) {
    dateStart = new Date();
  }

  const dateEnd = setHours(new Date(dateStart), filter?.time?.value[1]);
  const teetimes = getTeeTimeSummary(dateStart, dateEnd, course);
  let displayPrice = "";

  if (course?.price_min) {
    displayPrice += Number(course.price_min).toFixed(0);
  }
  if (
    course?.price_min &&
    course?.price_max &&
    Number(course.price_max).toFixed(0) !== Number(course.price_min).toFixed(0)
  ) {
    displayPrice += " - " + Number(course.price_max).toFixed(0);
  }

  const courseNameWithFacility =
    course.course_name === course.facility_name
      ? course.course_name
      : `${course.facility_name} - ${course.course_name}`;

  return (
    <Container>
      <Box
        sx={{ padding: 2 }}
        onClick={() => navigate.toCoursePage(course.course_id)}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2">{courseNameWithFacility}</Typography>
          <Typography variant="subtitle1" color="textPrimary">
            {displayPrice === "0" ? "See course page" : `$${displayPrice}`}
          </Typography>
        </Box>
        {course?.distance && (
          <Typography variant="caption">
            {Number(course.distance).toFixed(1)} miles
          </Typography>
        )}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            {course.rating && (
              <>
                <StarIcon
                  sx={{
                    color: theme.palette.primary.main,
                    marginRight: theme.spacing(0.4),
                    fontSize: theme.spacing(1.4),
                  }}
                  fontSize="small"
                />
                <Typography sx={{ fontWeight: "bold" }} variant="caption">
                  {Number(course.rating).toFixed(1)}{" "}
                  {getRatingWord(course.rating)}
                </Typography>
              </>
            )}
          </Box>
          <Box display="flex" alignItems="center">
            <ClockIcon color="primary" />
            {teetimes.map((t, index) => (
              <Typography
                key={index}
                variant="caption"
                sx={{
                  width: theme.spacing(2.8),
                  height: theme.spacing(2.8),
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "0.799862px solid #3D6C45",
                  color: !t[1] ? "#E2E3E4" : theme.palette.primary.main,
                  borderRadius: theme.spacing(1),
                  marginLeft: 2,
                  borderColor: !t[1] ? "inherit" : "#E2E3E4",
                }}
              >
                {t[0]}
              </Typography>
            ))}
          </Box>
        </Box>
      </Box>
    </Container>
  );
};
