import { format } from "date-fns";
import { Box, Typography, useTheme } from "@mui/material";
import { ClockIcon, GolfIcon, StaffIcon } from "@common";

type BookingTeeTimeCardProps = {
  className?: string;
  playerMax: number;
  playerMin: number;
  time: string;
  holesCount: number;
  price?: number | null;
};

export const BookingTeeTimeCard = ({
  playerMax,
  playerMin,
  time,
  holesCount,
  price,
}: BookingTeeTimeCardProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        padding: theme.spacing(1.4),
        border: "0.8px solid #E2E3E4",
        boxSizing: "border-box",
        borderRadius: theme.spacing(1),
      }}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box alignItems="center" display="flex" justifyContent="space-between">
        <ClockIcon sx={{ marginRight: 0.8 }} />
        <Typography sx={{ marginRight: 0.8 }} variant="body2">
          {time && format(new Date(time), "h:mm a")}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <StaffIcon sx={{ marginRight: 0.8 }} />
        <Typography sx={{ marginRight: 0.8 }} variant="body2">
          {playerMin === playerMax ? playerMin : `${playerMin}-${playerMax}`}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <GolfIcon sx={{ marginRight: 0.8 }} />
        <Typography sx={{ marginRight: 0.8 }} variant="body2">
          {holesCount}
        </Typography>
      </Box>
      <Typography variant="subtitle1" color="textPrimary">
        ${price}
      </Typography>
    </Box>
  );
};
