import { Container, Box, Typography } from "@mui/material";
import GolfSwingBackgroundImg from "@assets/images/golf-swing-background.png";
import { useTheme } from "@mui/material";

export const AboutPage = () => {
  const theme = useTheme();

  return (
    <Container sx={{ padding: 0 }}>
      <Typography
        variant="h1"
        color="primary"
        sx={{
          background: "white",
          padding: theme.spacing(0.5),
          paddingTop: theme.spacing(1.5),
          borderBottom: `3px solid ${theme.palette.primary.main}`,
          position: "fixed",
          left: 0,
          right: 0,
          fontWeight: "bold",
          fontSize: theme.spacing(2.2),
        }}
      >
        An easier way to find tee times
      </Typography>

      <Box sx={{ width: "100%", marginTop: theme.spacing(5) }}>
        <img src={GolfSwingBackgroundImg} alt="" />
      </Box>
      <Box sx={{ padding: theme.spacing(1) }}>
        <Typography sx={{ lineHeight: 1.4 }} variant="h6">
          <b>Booking golf online sucks.</b>
          <br />
          <br />
          Calling courses or going to their websites one by one to find a tee
          time. Paying a $4.50 "convenience fee" to book through third parties.
          Booking online is a pain in the ass.
          <br />
          <br />
          TeeTimePortal is here to help.
          <br />
          <br />
          TeeTimePortal grazes thousands of tee time sites a day to give you
          up-to-the minute tee times and prices in a single, easy to use
          website. Find your tee time faster on TeeTimePortal, and we'll direct
          you to the course website to complete the booking.
        </Typography>
        <br />
        <Typography sx={{ lineHeight: 1.4 }} variant="h6">
          <b>Not seeing a course or city on TeeTimePortal?</b>
          <br />
          <br />
          We're just getting started, so TeeTimePortal hasn't made it to every
          course or city yet. If you'd like us to add something, create a
          profile, request a course or city, and we'll add to the site as soon
          as we can.
          <br />
          <br />
          Stop wasting your time, start using TeeTimePortal.
        </Typography>
      </Box>
    </Container>
  );
};

export default AboutPage;
