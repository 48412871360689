import { BrowserRouter, Route, Routes } from "react-router-dom";
import PageNotFound from "@pages/PageNotFound";
import SearchPage from "@pages/SearchPage/SearchPage";
import LoginPage from "@pages/LoginPage/LoginPage";
import ChangePasswordPage from "@pages/ChangePasswordPage/ChangePasswordPage";
import AccountPage from "@pages/AccountPage/AccountPage";
import AboutPage from "@pages/AboutPage/AboutPage";
import ResultsPage from "@pages/ResultsPage/ResultsPage";
import CoursePage from "@pages/CoursePage/CoursePage";
import styled from "@emotion/styled";
import { Footer, NavMenu } from "@common";
import LandingPage from "@pages/LandingPage/LandingPage";
import CreateAccountPage from "@pages/CreateAccountPage/CreateAccountPage";
import { useAuthenticatedUser } from "@services/Authentication";
import { SuccessPage } from "@pages/SuccessPage/SuccessPage";
import VerifyPasswordPage from "@pages/VerifyPasswordPage/VerifyPasswordPage";
import { CancelPage } from "@pages/CancelPage/CancelPage";
import { AuthRedirectPage } from "@pages/RedirectPage/AuthRedirectPage";

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
export default function AppRouter() {
  const isAuthenticated = useAuthenticatedUser();

  return (
    <AppContainer>
      <BrowserRouter>
        <NavMenu />
        <Routes>
          {isAuthenticated && (
            <>
              <Route path="/" element={<SearchPage />} />
              <Route path="/account" element={<AccountPage />} />
            </>
          )}
          {!isAuthenticated && (
            <>
              <Route path="/" element={<LandingPage />} />
            </>
          )}

          <Route path="/about" element={<AboutPage />} />
          <Route path="/results" element={<ResultsPage />} />
          <Route
            path="/course/:courseId/:tabId?/:secondaryId?"
            element={<CoursePage />}
          />
          <Route path="/change-password" element={<ChangePasswordPage />} />
          <Route path="/verify" element={<VerifyPasswordPage />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/cancel" element={<CancelPage />} />
          <Route path="/create-account" element={<CreateAccountPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/redirect/auth" element={<AuthRedirectPage />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </AppContainer>
  );
}
