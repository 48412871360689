import { useGetUser } from "@thesparklaboratory/teetimeportal-react-query-client";
import { useAuthenticatedUser } from "@services/Authentication";
import { useAppErrorHandlers } from "@services/ErrorMessaging";
import { isAfter, parseISO } from "date-fns";
import { useState } from "react";

export function useUser() {
  const authenticatedUser = useAuthenticatedUser();
  const isUserAuthenticated = !!authenticatedUser?.idToken;
  const useGetUserReturn = useGetUser({
    query: {
      enabled: isUserAuthenticated,
    },
  });

  useAppErrorHandlers([
    {
      error:
        isUserAuthenticated && useGetUserReturn.error
          ? useGetUserReturn.error
          : undefined,
      id: "useUser",
    },
  ]);

  return useGetUserReturn;
}

export const useConfirmSubscriptionUpdate = ({
  currentValue,
  enabled,
  disableFct,
}: {
  currentValue?: string;
  enabled: boolean;
  disableFct: () => void;
}) => {
  const [isPending, setIsPending] = useState(false);

  const useGetUserReturn = useGetUser({
    query: {
      enabled,
      refetchInterval: (data) => {
        const newValue = data?.state?.data?.data?.subscription?.updatedAt;
        if (!newValue || !currentValue) return 1000;
        if (isAfter(parseISO(newValue), parseISO(currentValue))) {
          disableFct();
          return false;
        }
        setIsPending(enabled);
        return 1000;
      },
    },
  });

  return { ...useGetUserReturn, isPending };
};
