type AppConfig = {
  googleMapsApiKey: string;
  baseUrl: string;
  isAssistantEnabled?: boolean;
  supportEmail?: string;
  sentryDsn?: string;
  stage: string;
  googleTenantId: string;
};

export const appConfig: AppConfig = {
  stage: import.meta.env.VITE_APP_STAGE || "local",
  sentryDsn: "https://4f61192daa01405c9c771dcec98b2e25@sentry.sparklabs.us/56",
  supportEmail: "teetimeportal@golfconnect.com",
  isAssistantEnabled: false,
  googleMapsApiKey: "AIzaSyB_ltXGgvedWpcsxdXUefQl3m2shF1ViMM",
  baseUrl: import.meta.env.VITE_API_BASE_URL,
  googleTenantId: import.meta.env.VITE_APP_GOOGLE_TENANT_ID,
};
