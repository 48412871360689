import { Grid, LoadingScreen, SearchBar } from "@common";
import { Container } from "@mui/material";
import { useCourses } from "@services/Courses";
import { Results } from "./Results";
import { useSearchBar } from "@services/Search";
import { useAppNavigate } from "@services/Navigation";

const ResultsPage = () => {
  const navigate = useAppNavigate();
  const searchBar = useSearchBar({
    onSubmit: ({ filter }) => navigate.toResults(filter),
  });
  const { courses, isLoading } = useCourses({ filter: searchBar.filter });

  return (
    <LoadingScreen isLoading={isLoading}>
      <Container sx={{ marginTop: 4, flex: 1 }}>
        <Grid container spacing={6}>
          <Grid xs={12} md={10}>
            <SearchBar {...searchBar} />
          </Grid>
          <Grid xs={12}>
            <Results courses={courses} />
          </Grid>
        </Grid>
      </Container>
    </LoadingScreen>
  );
};

export default ResultsPage;
