import { Button, Container, Grid, Typography } from "@mui/material";
import SuccessCircle from "@assets/images/success-circle.svg";
import { useURLSearchParams } from "@/hooks/useURLSearchParams";
import { RouterLink as Link } from "@common";
import { addDays, format } from "date-fns";
export const SuccessPage = () => {
  const query = useURLSearchParams();
  const isBasic = query.get("sub") === "free";
  const isPremium = query.get("sub") === "premium";
  const DaysFromNow_30 = format(addDays(new Date(), 30), "MMMM d, yyyy");
  const DaysFromNow_365 = format(addDays(new Date(), 365), "MMMM d, yyyy");

  return (
    <Container
      sx={{
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          textAlign: "center",
        }}
      >
        <Grid item xs={12}>
          <img src={SuccessCircle} alt={"success-circle"} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant={"h3"} gutterBottom>
            Success!
          </Typography>
        </Grid>
        {isBasic && (
          <Grid item xs={12}>
            <Typography variant={"p2"} color={"text.grey"} gutterBottom>
              Your Account has been created!
            </Typography>
          </Grid>
        )}
        {isPremium && (
          <>
            <Container maxWidth={"sm"}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant={"p2"} color={"text.grey"} gutterBottom>
                    Your premium account has been created! Your current
                    subscription period will renew on {DaysFromNow_365}.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant={"p2"} color={"text.grey"} gutterBottom>
                    If you cancel anytime on or before that date you will not be
                    charged after your current subscription period ends.
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </>
        )}

        {!isPremium && !isBasic && (
          <>
            <Container maxWidth={"sm"}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant={"p2"} color={"text.grey"} gutterBottom>
                    Your account has been created and 30-day Premium trial has
                    started. Your current trial subscription period will last
                    until
                    <br /> {DaysFromNow_30}.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant={"p2"} color={"text.grey"} gutterBottom>
                    If you cancel anytime on or before that date you will not be
                    charged after your current trial subscription period ends.
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </>
        )}
        <Grid item xs={12}>
          <Link to={"/"}>
            <Button variant={"secondary"}>Find a tee time</Button>
          </Link>
        </Grid>
      </Grid>
    </Container>
  );
};
