import { Box, Button, Grid, Typography } from "@mui/material";
import {
  Control,
  Controller,
  Path,
  UseFormHandleSubmit,
} from "react-hook-form";
import { emailRegexp, passwordErrorMessage, passwordPattern } from "@utils";
import * as yup from "yup";
import {
  AddressCompleteWithController,
  TextFieldPasswordWithController,
  TextFieldWithController,
  RouterLink as Link,
} from "@common";
import { styled } from "@mui/material/styles";

const SubscriptionChoiceContainer = styled("label")<{ isActive: boolean }>(
  ({ theme, isActive }) => ({
    border: `1px solid ${
      isActive ? theme.palette.green.primary : theme.palette.grey[600]
    }`,

    borderRadius: theme.spacing(1),
    padding: theme.spacing(1.5),
    display: "flex",
    flexDirection: "column",
    height: "80px",
    cursor: "pointer",
    input: {
      display: "none",
    },
  }),
);

const SubscriptionChoice = ({
  name,
  value,
  control,
  label,
  price,
  line2,
}: {
  name: Path<CreateAccountFormValues>;
  value: string;
  control: Control<CreateAccountFormValues>;

  label: string;
  price: string;
  line2?: string;
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        const { name, onChange } = field;
        return (
          <SubscriptionChoiceContainer
            isActive={field.value === value}
            htmlFor={label}
          >
            <Box
              sx={{
                display: "flex",
                flex: 1,
                justifyContent: "space-between",
              }}
            >
              <input
                id={label}
                name={name}
                type="radio"
                value={value}
                onChange={onChange}
              />
              <Typography variant={"p2"}>{label}</Typography>
              <Typography variant={"h6"}>{price}</Typography>
            </Box>
            {line2 && (
              <Typography
                variant={"f2"}
                sx={{ color: "text.grey", fontSize: 14 }}
              >
                {line2}
              </Typography>
            )}
          </SubscriptionChoiceContainer>
        );
      }}
    />
  );
};

export const createAccountSchema = yup.object().shape({
  first: yup.string().required("First name is required"),
  last: yup.string().required("Last name is required"),
  email: yup
    .string()
    .matches(emailRegexp, "Invalid email address")
    .required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .matches(passwordPattern, passwordErrorMessage)
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),

  location: yup
    .object({
      address: yup.object({
        city: yup.string().required("Location is required"),
        region: yup.string().required("Location is required"),
      }),
      description: yup.string().required("Location is required"),
      geocode: yup.object({
        lat: yup.number().required("Location is required"),
        lng: yup.number().required("Location is required"),
      }),
    })
    .required("Location is required"),
  subscription: yup.string().required("Subscription is required"),
});

export type CreateAccountFormValues = yup.InferType<typeof createAccountSchema>;

export const CreateAccountForm = ({
  onSubmit,
  control,
  isFree,
}: {
  onSubmit: ReturnType<UseFormHandleSubmit<CreateAccountFormValues>>;
  control: Control<CreateAccountFormValues>;
  isFree?: boolean;
}) => {
  return (
    <Grid container component={"form"} onSubmit={onSubmit} spacing={2}>
      <Grid
        item
        xs={12}
        sx={{ textAlign: "center", mt: 3, mb: { xs: 1, md: 2 } }}
      >
        <Typography variant={"h3"}>Create Account</Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <TextFieldWithController
          control={control}
          name="first"
          placeholder="Input first name"
          label="First Name"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextFieldWithController
          control={control}
          name="last"
          placeholder="Input last name"
          label="Last Name"
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <TextFieldWithController
          control={control}
          name="email"
          placeholder="Input email"
          label="Email"
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <TextFieldPasswordWithController
          control={control}
          name="password"
          placeholder="Input password"
          label="Password"
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <AddressCompleteWithController control={control} name="location" />
      </Grid>

      <Grid item xs={12} md={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <SubscriptionChoice
              control={control}
              name="subscription"
              value="free"
              label="Basic"
              price="Free"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SubscriptionChoice
              control={control}
              name="subscription"
              value="trial"
              label="Premium"
              price="$39.99/year"
              line2="30-day free trial"
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} display="flex" justifyContent="center">
        <Button type="submit" variant="secondary">
          {isFree ? "Create a Basic Account" : "Start a 30-day Premium trial  "}
        </Button>
      </Grid>

      <Grid item xs={12} display="flex" justifyContent="center" mb={5}>
        <Typography variant={"p2"}>Already have an account?</Typography>

        <Link to={"/login"}>
          <Typography variant={"p2"} sx={{ ml: 1, fontWeight: "bold" }}>
            Log In
          </Typography>
        </Link>
      </Grid>
    </Grid>
  );
};

export default CreateAccountForm;
