import {
  CreateAccountForm,
  CreateAccountFormValues,
  createAccountSchema,
} from "@pages/CreateAccountPage/CreateUserForm";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useURLSearchParams } from "@/hooks/useURLSearchParams";
import { submitLogin } from "@services/Authentication";
import { logger } from "@services/Logger";

import {
  useCreateUser,
  useGetStripeCreateCheckoutSession,
  useGetStripeSubscriptionProducts,
} from "@thesparklaboratory/teetimeportal-react-query-client";
import { useSnackbar } from "notistack";
import { useAppNavigate } from "@services/Navigation";
import { LoadingWrapper } from "@common";
import CheckoutConfirmationModal from "@common/CheckoutConfirmModal";
import { AccountPageContainer } from "@common/AccoutPageContainer";

export default function CreateAccountPage() {
  const query = useURLSearchParams();
  const isTrial = query.get("sub") === "trial";
  const navigate = useAppNavigate();

  const createAccount = useForm({
    resolver: yupResolver(createAccountSchema),
    defaultValues: {
      subscription: isTrial ? "trial" : "free",
    },
  });
  const { data: productsResponse } = useGetStripeSubscriptionProducts();
  const isFree = createAccount.watch("subscription") === "free";
  const { enqueueSnackbar } = useSnackbar();
  const {
    mutate: createCheckoutSession,
    isPending: isCreateSubscriptionPending,
  } = useGetStripeCreateCheckoutSession();

  const handleCreateCheckoutSession = async () => {
    const { email } = createAccount.getValues();
    const productId = productsResponse?.data[0]?.id;

    if (!email || !productId) {
      logger.error({
        message: "Error creating checkout session",
        error: "Email or product id is missing",
        data: { email, productId },
      });
      return enqueueSnackbar("Error signing up, please try again", {
        variant: "error",
      });
    }

    createCheckoutSession(
      {
        data: {
          email,
          productId,
          isTrial: true,
          successUrl: "/success",
          cancelUrl: "/cancel",
        },
      },
      {
        onSuccess: (data: { data: string }) => {
          const checkoutUrl = data?.data;
          window.location.href = checkoutUrl; // Redirect to the provided URL
        },
        onError: (error) => {
          enqueueSnackbar("Subscription was not created, please try again", {
            variant: "error",
          });
          logger.error({ message: "Error creating subscription", error });
        },
      },
    );
  };
  const { mutate: createUser, isPending: isSignUpPending } = useCreateUser({
    mutation: {
      onSuccess: async () => {
        const isTrial = createAccount.getValues("subscription") === "trial";
        const { email, password } = createAccount.getValues();
        await submitLogin({ email, password });
        if (!isTrial) {
          return navigate.toBasicSuccess();
        } else {
          await handleCreateCheckoutSession();
        }
      },
      onError: (error) => {
        logger.error({ message: "Error signing up user", error });
        enqueueSnackbar("Error signing up, please try again", {
          variant: "error",
        });
      },
    },
  });

  const handleCreateAccountSubmit = createAccount.handleSubmit(
    (createAccountForm: CreateAccountFormValues) => {
      createUser({
        data: {
          email: createAccountForm.email,
          address: {
            city: createAccountForm.location.address.city,
            region: createAccountForm.location.address.region,
            latitude: createAccountForm.location.geocode.lat,
            longitude: createAccountForm.location.geocode.lng,
          },
          password: createAccountForm.password,
          firstName: createAccountForm.first,
          lastName: createAccountForm.last,
        },
      });
    },
  );
  return (
    <>
      <CheckoutConfirmationModal open={isCreateSubscriptionPending} />
      <LoadingWrapper visible={isSignUpPending}>
        <AccountPageContainer>
          <CreateAccountForm
            onSubmit={handleCreateAccountSubmit}
            control={createAccount.control}
            isFree={isFree}
          />
        </AccountPageContainer>
      </LoadingWrapper>
    </>
  );
}
