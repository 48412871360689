import { useJsApiLoader } from "@react-google-maps/api";
import { useEffect } from "react";
import { logger } from "@services/Logger";
import { appConfig } from "@services/AppConfig";
import { Library } from "@googlemaps/js-api-loader";

export type Address = {
  city: string;
  latitude: number;
  longitude: number;
  region: string;
};

const libraries: Library[] = ["places", "geometry"];
export function useGoogleMapsApi() {
  const { isLoaded: isGoogleMapsLoaded, loadError: googleMapsLoadError } =
    useJsApiLoader({
      id: "google-map-script",
      googleMapsApiKey: appConfig.googleMapsApiKey,
      libraries: libraries,
    });

  useEffect(() => {
    if (googleMapsLoadError) {
      logger.error({
        message: "Google Maps failed to load",
        error: googleMapsLoadError,
      });
    }
  }, []);
  return isGoogleMapsLoaded;
}

export function getAddressAndGeocodeFromResults(
  result: google.maps.GeocoderResult,
): {
  address: Address;
  geocode: { lat: number; lng: number };
} {
  const address = result.address_components.reduce((acc, addressComponent) => {
    if (addressComponent.types.includes("locality")) {
      acc.city = addressComponent.long_name;
    } else if (addressComponent.types.includes("administrative_area_level_1")) {
      acc.region = addressComponent.long_name;
    }
    return acc;
  }, {} as Address);

  const geocode = {
    lat: result.geometry.location.lat(),
    lng: result.geometry.location.lng(),
  };

  return { address, geocode };
}
