import { Box, Card, CardContent, Typography } from "@mui/material";

export const CurrentPlan = ({
  type,
  price,
  trialLeft,
  renewsAt,
  cancelsAt,
}: {
  type: string;
  price: string;
  trialLeft: number | null;
  renewsAt?: string | null;
  cancelsAt?: string | null;
}) => {
  return (
    <Card
      variant="outlined"
      sx={{
        borderColor: "grey.600",
        borderRadius: "4px",
        position: "relative",
        overflow: "visible",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "4px",
          position: "absolute",
          top: -14,
          left: 12,
          zIndex: 1,
          background: "white",
        }}
      >
        <Typography variant={"f1"} gutterBottom>
          Current Plan
        </Typography>
      </Box>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="p2">{type}</Typography>
          <Typography variant="h6" fontWeight={700}>
            {price}
          </Typography>
        </Box>
        {trialLeft && (
          <Typography variant={"p2"} color="text.grey" fontStyle={"italic"}>
            {trialLeft} days left of your free trial!
          </Typography>
        )}

        {!cancelsAt && renewsAt && (
          <Box>
            <Typography variant={"p2"} color="text.grey" fontStyle={"italic"}>
              Renews on {renewsAt}
            </Typography>
          </Box>
        )}

        {cancelsAt && (
          <Box>
            <Typography variant={"p2"} color="text.grey" fontStyle={"italic"}>
              Your subscription is set to cancel on {cancelsAt}
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};
