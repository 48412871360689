import { useRef, useState } from "react";
import { IconButton, InputAdornment, Paper, TextField } from "@mui/material";
import { Button, TimeSlider } from "@common";
import { styled } from "@mui/material/styles";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { WatchLater } from "@mui/icons-material";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";

const ApplyButton = styled(Button)(({ theme }) => ({
  margin: 0,
  color: theme.palette.primary.main,
}));

const Container = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor: "#fff",
  textAlign: "center",
  [theme.breakpoints.up("md")]: {
    width: "max-content",
    top: theme.spacing(5.5),
  },
}));

type TimeSelectProps<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  time?: {
    alignment: string;
    value: number[];
  };
};

export function TimeSelect<T extends FieldValues>({
  time,
  name,
  control,
}: TimeSelectProps<T>) {
  const textRef = useRef<HTMLInputElement>(null);
  const [isOpen, setOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setOpen((previousOpen) => !previousOpen);
  };
  const canBeOpen = isOpen && Boolean(textRef.current);
  const id = canBeOpen ? "transition-popper" : undefined;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { name, onChange, value } }) => (
        <>
          <TextField
            ref={textRef}
            fullWidth
            name={name}
            onClick={handleClick}
            placeholder="Any Time"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    aria-describedby={id}
                    sx={{ marginLeft: "-12px" }}
                    onClick={handleClick}
                  >
                    <WatchLater sx={{ color: "green.primary" }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={value?.alignment}
          />

          <Popper
            id={id}
            open={isOpen}
            anchorEl={textRef.current}
            transition
            sx={{ zIndex: 1200 }}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Container>
                  <TimeSlider time={time} onChange={onChange} />
                  <ApplyButton
                    variant="text"
                    color="primary"
                    fullWidth={false}
                    onClick={() => setOpen(false)}
                  >
                    Apply
                  </ApplyButton>
                </Container>
              </Fade>
            )}
          </Popper>
        </>
      )}
    />
  );
}
