import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { RouterLink as _RouterLink } from "@/components/common/RouterLink";
import {
  PersonIcon as _PersonIcon,
  LogOutIcon,
} from "@/components/common/Icons";
import { Break } from "./Break";
import { styled } from "@mui/material/styles";
import AssistantIcon from "@mui/icons-material/Assistant";
import { useAppNavigate } from "@services/Navigation";
import { signOut } from "@services/Authentication";
import { appConfig } from "@services/AppConfig";
import { useAppErrorHandlers } from "@services/ErrorMessaging";
import { useUser } from "@services/User";

const { isAssistantEnabled } = appConfig;
const Container = ({ children }: { children: React.ReactNode }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      width: 326,
      paddingRight: 3,
      paddingLeft: 3,
    }}
  >
    {children}
  </Box>
);

const RouterLink = styled(_RouterLink)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  display: "flex",
  alignItems: "center",
  color: theme.palette.text.primary,
  cursor: "pointer",
  textDecoration: "none",
}));

const PersonIcon = styled(_PersonIcon)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: theme.spacing(3),
  borderRadius: "50%",
  width: 52,
  height: 52,
  padding: 13,
  border: `1px solid ${theme.palette.text.secondary}`,
  background: "transparent",
}));

const AccountLink = styled(_RouterLink)(({ theme }) => ({
  fontWeight: "bold",
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  textDecoration: "none",
}));

export const Menu = ({ toggleDrawer }: { toggleDrawer: () => void }) => {
  const { data: userResponse, error: userError } = useUser();
  const user = userResponse?.data;
  useAppErrorHandlers([{ error: userError, id: "user" }]);
  const navigate = useAppNavigate();
  const [logOutError, setLogOutError] = useState("");

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate.toHome();
    } catch (error) {
      setLogOutError("error signing out");
    }
  };

  const assistantContainerElement = isAssistantEnabled ? (
    <Container>
      <RouterLink color="textSecondary" to="/assistant">
        AI Assistant&nbsp;
        <AssistantIcon />
      </RouterLink>
    </Container>
  ) : null;

  const element = user?.email ? (
    <Box onClick={toggleDrawer}>
      <Container>
        <PersonIcon />
        <Typography sx={{ marginTop: 1 }} variant="subtitle1">
          {user?.firstName} {user?.lastName}
        </Typography>
      </Container>
      <Break />
      <Container>
        <RouterLink color="textSecondary" to="/account">
          <_PersonIcon sx={{ marginRight: 1 }} />
          My Account
        </RouterLink>
      </Container>
      <Break />
      <Container>
        <RouterLink color="textSecondary" to="/about">
          {" "}
          About TeeTimePortal
        </RouterLink>
        <RouterLink onClick={handleSignOut} color="textSecondary" to="/">
          <LogOutIcon sx={{ marginRight: 1 }} />
          Log Out{" "}
        </RouterLink>
        {logOutError && <Typography color="error">{logOutError}</Typography>}
      </Container>
      {assistantContainerElement}
    </Box>
  ) : (
    <Box>
      <Container>
        <PersonIcon />
        <AccountLink
          onClick={toggleDrawer}
          color="textSecondary"
          to="/create-account"
        >
          + Create an Account
        </AccountLink>
      </Container>
      <Break />
      <Container>
        <RouterLink onClick={toggleDrawer} color="textSecondary" to="/about">
          {" "}
          About TeeTimePortal
        </RouterLink>
        <RouterLink onClick={toggleDrawer} to="/login">
          Login
        </RouterLink>
      </Container>
      {assistantContainerElement}
    </Box>
  );

  return element;
};
